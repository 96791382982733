

.pre-register {
  margin-top: 150px;
}

::v-deep img {
  width: 100%;
}

.pre-register-content {
  // padding-top: 4px
  // margin-top: 4px
  @media screen and (max-width: 960px) {
    padding-top: 8px
    margin-top: 8px
  }
}

.gallery {
  height 100%
  max-height: 500px
  @media screen and (max-width: 960px) {
    height: 500px
    display: flex
    justify-content: center
    width: 100%
  }
}

::v-deep .thumb-gallery{
  height 100%
}

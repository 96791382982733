

.close
  display none
  @media medium
    display: flex
    justify-content: flex-end
  @media small
    display: flex
    justify-content: flex-end


.customer-form
    background $color-bnn-gray
    padding-top 1rem
    padding-bottom 3rem
    margin-top 4rem
    width 100%
.form-customer-layout
    width 100%
    transition: height 0.25s ease;
    margin-top 1rem
    @media medium
      margin-top 1rem
      width 100%
      padding 0 1rem
    @media small
      margin-top 1rem
      width 100%
      padding 0 1rem
.v-text-field.v-text-field--enclosed .v-text-field__details
  margin-bottom 0 !important
.v-messages.theme--light
  min-height 0px !important
.v-input--selection-controls
  margin-top 0px !important
.input-transparent
  background: transparent !important;
  border-color: transparent !important;
.pre-register-form
  margin-top 0
  @media small
      width: 100%
      background: white
      position fixed
      bottom: 0
      left 0
      z-index: 50
      padding: 2rem 1rem
      box-shadow: 1px 1px 6px #00000029;
      border-radius: 4px
  @media medium
      width: 100%
      background: white
      position fixed
      bottom: 0
      left 0
      z-index: 50
      padding: 2rem 1rem
      box-shadow: 1px 1px 6px #00000029;
      border-radius: 4px

.btn-register
  width 100%
  padding 0 1rem
  display: none
  @media medium
    display: block
  @media small
    display: block

.form-layout
  height auto
  width 100%
  transition: max-height 0.15s ease;
  padding-top: 4px
  @media medium
    height 0px
    padding-top 0
    &--show
      height 640px
      transition: height 0.25s ease;
  @media small
    height 0px
    padding-top 0
    &--show
      height 640px
      transition: height 0.25s ease;
